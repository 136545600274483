<template>
  <main class="Financial">
    <TheSubHeader  
      ref="theSubHeader" 
      icon="money"
      title="Financeiro"
      subtitle="Aprovação de Analistas"
      page-title-tag="Financial Analyst Review"
      type="routerTabs"
      organizer-gap="1rem"
    >
      <template #afterButtons>
        <BaseButton
          v-if="permissions && permissions.editVehicles && permissions.editVehicles.isVisible"
          icon="truck-delivered"
          color="primary-base"
          label="Tipo de Veículo"
          class="car-type-button"
          :track="trackSubheaderButtonString('Financial Analyst Review', 'Financial Edit Vehicle')"
          @click="goToEditVehicle()"
        />
        
        <BaseButton
          icon="status"
          color="primary-base"
          label="Custo de operação"
          class="operation-button"
          :track="trackSubheaderButtonString('Financial Analyst Review', 'Operation Cost')"
          @click="openModalOperation()"
        />
      </template>

      <template #filter>
        <section class="Financial__subheaderTab">  
          <!-- Drivers filter -->
          <BaseTreeSelect
            v-model="filters.driver"
            name="Motorista"
            label="Motorista / Beneficiário"
            placeholder="Pesquisar"
            multiple
            suggestions="searchDrivers"
            @input="updateTags(), handlerAnalystReviewRequest()"
          />

          <!-- Operation filter -->
          <BaseTreeSelect
            v-model="filters.operationType"
            name="Operation"
            label="Operação"
            placeholder="Selecione"
            :searchable="false"
            :enable-paste="false"
            :select-options="operationType"
            @input="handlerAnalystReviewRequest()"
          />

          <!-- Stores filter -->
          <BaseTreeSelect
            v-model="filters.store"
            name="Cliente"
            label="Cliente"
            placeholder="Selecione"
            multiple
            :enable-paste="false"
            :select-options="null"
            :fetch-options="'stores/fetchStoresAsync'"
            @input="updateTags(), handlerAnalystReviewRequest()"
          />

          <!-- Status filter -->
          <BaseTreeSelect
            v-model="filters.status"
            name="Status"
            label="Status"
            placeholder="Selecione"
            :enable-paste="false"
            :select-options="statusList"
            @input="handlerAnalystReviewRequest()"
          />
        </section>
      </template>

      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>   

    <section class="Financial__content">
      <DataTable
        :header="analystReview.data.headers"
        :table="analystReview.data.content"
        :input-value="inputValue"
        @comment="handlerChatMessenger"
        @btnPreapproved="handleSendToManager"
        @btnNewJustification="handleSendToManager"
      />

      <div class="Financial__operations">
        <div v-for="item in operations" :key="item.color" class="Financial__operations--item">
          <span class="circle" :class="`circle-${item.color}`" />
          {{ item.label }}
        </div>
      </div>

      <Pagination @page="fetchAnalystReviewItems" @paginate="fetchAnalystReviewItems">
        <div class="Financial__cost">
          <strong>{{ analystReview.data.append.end_day }}</strong>
        </div>
      </Pagination>

      <ChatMessenger 
        ref="ChatMessenger" 
        :messages="analystsComments.data.content"
        :suggestions="justifyOptions"
        :disable-send="disableSend"
        accept-file
        @fetchMessages="fetchMessages"
        @sendMessage="sendMessage"
        @hasFile="hasFileAttached"
        @input="onChatInput"
      />

      <Dialog
        v-if="dialog"
        class="dialog"
        warning="Os dados e valores serão enviados ao seu gestor para aprovação."
        label-confirm="Confirmar"
        label-cancel="Cancelar"
        @confirm="onFinancialAnalystSend"
        @cancel="dialog = false"
      />
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
//Components
import { DataTable, Pagination, FiltersTag, ChatMessenger, Dialog } from '@/components/molecules'
import { financial } from '@/api'
import { TheSubHeader } from '@/components/organisms'
import { BaseButton, BaseTreeSelect } from '@/components/atoms'

//Mixins
import FinancialMixin from '@/mixins/financial'

export default {
  name: 'ReviewAnalyst',
  components: {
    TheSubHeader,
    BaseButton,
    FiltersTag,
    BaseTreeSelect,
    DataTable,
    Pagination,
    ChatMessenger,
    Dialog
  },
  mixins: [FinancialMixin],
  data() {
    return {
      //Filters
      filters: {
        driver: [],
        store: [],
        status: null,
        operationType: null
      },

      operations: [
        { label: 'Operação online', color: 'green' },
        { label: 'Operação offline', color: 'grey' }
      ],

      // operation option
      operationType: [
        {
          id: '0',
          name: 'Offline',
          label: 'Offline'
        },
        {
          id: 1,
          name: 'Online',
          label: 'Online'
        }
      ],

      //Tags
      allTags: [],

      dialog: false,
      inputValue: [],
      selectedItem: null,

      //suggestions
      justifyOptions: [
        {id: 0, name: 'Adicional KM'}, 
        {id: 1, name: 'Erro na quantidade de diárias e/ou número de pedidos', fileRequired: true},
        {id: 2, name: 'Erro na quantidade de pacotes', fileRequired: true}
      ],

      //has file
      hasAttachedFile: false,

      //message selected
      messageSelected: null
    }
  },

  computed: {
    ...mapState({
      paginate: state => state.pagination.paginate,
      page: state => state.pagination.page,
      analystReview: state => state.financial.analystReview,
      analystsComments: state => state.financial.analystsComments,
      permissions: state => state.auth.permissions,
    }),

    disableSend() {
      return !this.hasAttachedFile && this.messageSelected?.fileRequired
    }
  },
  
  mounted() {
    this.initStatusList()
    this.handlerAnalystReviewRequest()
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      setPagination: 'pagination/setPagination',
      clearPagination: 'pagination/clearPagination',
      fetchAnalystReview: 'financial/fetchAnalystReview',
      clearChatMessages: 'financial/clearChatMessages',
      fetchAnalystsComments: 'financial/fetchAnalystsComments'
    }),

    removeTag(name, id) {
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.driver = this.filters.driver.filter((tag) => tag.name !== name)
      this.filters.store = this.filters.store.filter((tag) => tag.name !== name)
      this.handlerAnalystReviewRequest()
    },

    clearAllTags() {
      this.allTags = []
      this.filters.store = []
      this.filters.driver = []
      this.filters.status = null
      this.filters.operationType = null
      this.handlerAnalystReviewRequest()
    },

    updateTags() {
      this.allTags = [...this.filters.store, ...this.filters.driver]
    },

    handlerAnalystReviewRequest() {
      this.clearPagination(20).then(() => this.fetchAnalystReviewItems())
    },

    hasFileAttached(hasFile) {
      this.hasAttachedFile = hasFile
    },

    onChatInput(data) {
      this.messageSelected = data
    },

    handleSendToManager(data) {
      if (!data.inputs.find(el => el.optionSelected)) {
        this.$toast.error(
          'É necessário adicionar algum valor para enviar ao gestor',
          {
            position: 'bottom-center'
          }
        )
        return false
      }

      this.dialog = true
      this.selectedItem = data
    },

    async onFinancialAnalystSend() {
      this.dialog = false
      this.setLoading(true)
      let params = {
        id: this.selectedItem.id,
        new_total_cost: this.selectedItem.inputs.find(el => el).optionSelected
      }

      await financial.submitReviewAnalyst(
        params,
        async r => {
          await this.fetchAnalystReviewItems()
          this.$toast.success(r.message, { position: 'bottom-center' })
        },
        e => e,
        () => this.setLoading(false)
      )
    },

    async fetchAnalystReviewItems() {
      const payload = {
        obj: {
          beneficiary_id: this.filters.driver,
          store_id: this.filters.store,
          type: this.filters.operationType?.id,
          'status_id[]': this.filters.status?.id,
        },
        page: this.page,
        paginate: this.paginate
      }

      await this.fetchAnalystReview(payload, (r) => r, (e) => e, () => this.setLoading(false))
      this.setPagination(this.analystReview)
    },

    handlerChatMessenger(data) {
      this.clearChatMessages().then(() => this.$refs.ChatMessenger.open(data.id))
    },

    async fetchMessages(id) {
      await this.fetchAnalystsComments(id).then(() => {
        this.$refs.ChatMessenger.scrollMessages()
      })
    },

    async sendMessage(id, message) {
      this.setLoading(true)
      const body = {
        operation_cost_id: id,
        message
      }
      await financial.submitAnalystMessage(body, async () => {
        await this.fetchMessages(id)
        await this.fetchAnalystReviewItems()
        this.$refs.ChatMessenger.resetInput()
      }, (e) => e)
    }
  }
}
</script>

<style lang="scss" scoped>
.Financial {
  &__subheaderTab {
    display: flex;
    align-items: flex-end;
    grid-gap: 1rem;
      @media #{$mobile-view} {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    padding: 0 0 3rem 0;
    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }
  }

  .dialog{
    /deep/.Dialog__Shadow {
      height: 100vh;
    }
  }

  &__operations {
    display: flex;
    margin-top: 1rem;
    gap: 20px;
    &--item {
      font-size: $font-size-3xsmall;
      gap: 5px;
      display: flex;
    }
  }

  .circle {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    &-grey {
      background-color: $color-neutral-soft;
    }
    &-green {
      background-color: $color-success-soft;
    }
  }

  &__cost {
    font-size: $font-size-mini;
    color: $color-neutral-stronger;
  }
}
</style>